@tailwind base;
@tailwind components;
@tailwind utilities;

ol,
ul {
  list-style-type: disc;
}

.active {
  --tw-shadow: 0 10px 15px -3px rgba(219, 234, 254, 1), 0 4px 6px -2px rgba(219, 234, 254, 1);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgb(219, 234, 254)), var(--tw-ring-shadow, 0 0 rgb(219, 234, 254)), var(--tw-shadow);
}

.markdown {
  height: calc(100vh - 2rem);
}

.markdown-header {
  backdrop-filter: blur(5px) saturate(1.5);
}

.markdown-body a {
  display: inline-block;
}

.markdown-body img {
  display: inline-block;
}

.markdown-body [align="center"] img {
  margin: 0 auto;
}
